import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


const breakpoints = {
  100: {
    slidesPerView: 3,
    spaceBetween: 5,
  },
  600: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};

const WeeklyWinners = () => {
  return (
    <div>
      <Heading
        className=""
        mt={"2em"}
        fontSize={["3xl", "5xl"]}
        fontWeight="bold"
        textAlign={"center"}
        maxW="800px"
        mx="auto"
      >
        MAG Weekly Champions
      </Heading>

      {/* <div
        className=""
        style={{
          margin: "20px",
          display: "flex",
          justifyContent: "center",
          gap: 25,
          flexWrap: "wrap",
        }}
      > */}
        <Swiper>
          {new Array(5).fill(0).map((_, index) => {
            const key = index + 1;
            return (
              <SwiperSlide
                loop={true}
                breakpoints={breakpoints}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                key={index}
                navigation={{
                  nextEl: ".swiper-button-next-two",
                  prevEl: ".swiper-button-prev-two",
                }}
                style={{
                  width: "300px",
                  margin: "auto",
                }}
              >
                {/* <div
                  className=""
                  style={{
                    margin: "10px",
                  }}
                > */}
                <Box
                  as="img"
                  width={["100%", "300px"]}
                  // height="300px"
                  style={{
                    // flex: 1,
                    margin: "15px auto",
                  }}
                  src={`winners/images/${key}.jpeg`}
                />
                {/* </div>   */}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div style={{
          opacity:.4,
          fontSize:20,
          marginTop:40,
          textAlign:"center"
        }} className=""><b>👈 Slide 👉 </b></div>
      </div>
    // </div>
  );
};

export default WeeklyWinners;
