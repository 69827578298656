import { Box, Heading, Image } from "@chakra-ui/react";
import React from "react";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const breakpoints = {
  100: {
    slidesPerView: 1,
    spaceBetween: 5,
  },
  600: {
    slidesPerView: 1.3,
    spaceBetween: 20,
  },
};

function CompletionSlider({ slides, title }) {
  return (
    <Box position="relative">
      <Heading
        fontSize={["3xl", "5xl"]}
        fontWeight="bold"
        textAlign="center"
        padding={10}
        marginBottom={30}
      >
        {title || "Celebrating Success! 🎉"}
      </Heading>
      <Swiper>
        {slides.map((slide, index) => {
          return (
            <SwiperSlide
              loop={true}
              breakpoints={breakpoints}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              key={index}

              // navigation={{
              //   nextEl: ".swiper-button-next-two",
              //   prevEl: ".swiper-button-prev-two",
              // }}
            >
              {/* <Box
                backgroundImage={`url(${slide.image})`}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                margin="auto"
                width={{ base: "80%", md: "50%" }}
                height={{ base: "500px", md: "500px" }}
              ></Box> */}
              <Image
                src={slide.image}
                margin="auto"
                w={{
                  base: "80%",
                  md: "30%",
                }}
              />
              <p
                style={{
                  margin: "30px auto",
                  maxWidth: "600px",
                  textAlign: "center",
                  fontStyle: "italic",
                  fontSize: 18,
                }}
              >
                {slide.caption}
              </p>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <Button
          // position="absolute"
          // top="50%"
          p={8}
          className="swiper-button-prev-two"
        ></Button>
        <Button
          // position="absolute"
          // top="50%"
          // right={0}
          p={8}
          className="swiper-button-next-two"
        ></Button> */}
      <div
        style={{
          opacity: 0.4,
          fontSize: 20,
          textAlign: "center",
        }}
        className=""
      >
        <b>👈 Slide 👉 </b>
      </div>
    </Box>
  );
}

export default CompletionSlider;
